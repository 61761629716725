<template>
  <div class="userAgreement wrapper">
    <div class="wrapper_inner">
      <a id="back_to_top" href="#" class="off">
        <span class="fa-stack">
          <i class="qode_icon_font_awesome fa fa-arrow-up"></i>
        </span>
      </a>

      <div
        class="content content_top_margin_none"
        style="padding-top: 0px; margin-bottom: 377.8px"
      >
        <div class="content_inner">
          <div class="container">
            <div
              class="
                container_inner
                default_template_holder
                clearfix
                page_container_inner
              "
            >
              <div
                class="vc_row wpb_row section vc_row-fluid grid_section"
                style="
                  background-color: #ffffff;
                  padding-top: 0px;
                  padding-bottom: 120px;
                  text-align: left;
                "
              >
                <div class="section_inner clearfix">
                  <div class="section_inner_margin clearfix">
                    <div class="wpb_column vc_column_container vc_col-sm-12">
                      <div class="vc_column-inner">
                        <div class="wpb_wrapper wpb_wrapper_container">
                          <div class="wpb_text_column wpb_content_element">
                            <div class="wpb_wrapper">
                              <h1 style="text-align: center;font-size: 22px;font-weight:bold;">{{agreement.title}}</h1>
                            </div>
                          </div>
                          <div class="wpb_text_column wpb_content_element">
                            <div class="wpb_wrapper" v-html="agreement.content"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "userAgreement",
  data() {
    return {
      agreement: {}
    }
  },
  created(){
    this.getAgreement();
  },
  methods: {
    getAgreement(){
      this.$http.post('/api/common/getAgreement',{type: 1}).then((data)=>{
        console.log(data);
        this.agreement = data;
      });
    }
  }
};
</script>
<style scoped>
.userAgreement {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
.wpb_wrapper{
  padding: 30px;
  max-width: 70%;
}
.vc_column-inner{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
